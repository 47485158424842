<template>
  <div class="container" :class='$mq'>

    <!-- MAIN SCREEN -->
    <div class="beg-screen" :class='$mq'>
      <div class="beg-top-left" :class='$mq'>
        <KImage :src="require('@/assets/begcourse/left-top.png')" alt="Art doll" width="100%"/>
      </div>
      <div class="beg-top-right" :class='$mq'>
        <KImage :src="require('@/assets/begcourse/right-top.png')" alt="Art doll" width="100%"/>
      </div>
      <div class="beg-bottom-left" :class='$mq'>
        <KImage :src="require('@/assets/begcourse/left-bottom.png')" alt="Art doll" width="100%"/>
      </div>
      <div class="light-pink" :class='$mq'></div>
      <div class="light-green" :class='$mq'></div>

      <!-- MAIN TEXT -->
      <div class="beg-h1" :class='$mq'>
        <h1>Научись cоздавать невероятную авторскую<br/>
          <span class='big'>куклу</span><br/>
          <span class='script'>за 2 месяца</span>
        </h1>
        <KRoundedButton class='beg-main-bt' text-color="#fff" color="#cc6157" v-scroll-to="'#price'">
          Создать куклу
          <template v-slot:icon>
            <KIHandFlower icon-color="#FFF" height="50px" width="50px"/>
          </template>
        </KRoundedButton>
      </div>

      <!-- MAIN IMAGE -->
      <div class="beg-img-block" :class='$mq'>
        <div class="beg-img" :class='$mq'>
          <KImage :src="require('@/assets/begcourse/main-img.jpg')" alt="Art doll" width="100%"/>
        </div>
        <div class="beg-video" :class='$mq' @click="playVideo()">
          <div class="beg-video-img" :class='$mq'>
            <KImage :src="require('@/assets/begcourse/video.png')" alt="Art doll" width="100%"/>
            <div class="beg-video-circle"></div>
          </div>
          <p>Посмотрите, как создается кукла</p>
        </div>
      </div>
    </div>

    <KRoundedButton class='beg-main-bt mob' :class='$mq' text-color="#fff" color="#cc6157" v-scroll-to="'#price'">
      Создать куклу
      <template v-slot:icon>
        <KIHandFlower icon-color="#FFF" height="50px" width="50px"/>
      </template>
    </KRoundedButton>

    <!-- ADVANTAGES -->
    <div class="beg-adv-cont" :class='$mq'>
      <div class="adv-line"></div>
      <div class="beg-adv" :class='$mq'>
        <div class="beg-adv-block" :class='$mq'>
          <p><span>300+</span><br/>учеников научились создавать кукол</p>
        </div>
        <div class="beg-adv-block" :class='$mq'>
          <p><span>23ч</span><br/>видео покажут весь процесс создания</p>
        </div>
        <div class="beg-adv-block" :class='$mq'>
          <p><span>13</span><br/>лет моего опыта, сконцентрированны в этом курсе</p>
        </div>
      </div>
    </div>

    <div class="video-mob-cont" :class='$mq'>
      <h2 :class='$mq'>Посмотрите <br/><span>как создается</span></h2>
      <div class="video-mob-block">
        <iframe class="video-mob" src="https://www.youtube.com/embed/yjKT_tmMuFw" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
      </div>
    </div>

    <!-- PRICING -->
    <div class="beg-pricing" :class='$mq' id='price'>
      <div class="beg-price-right" :class='$mq'>
        <KImage :src="require('@/assets/begcourse/left-bottom.png')" alt="Art doll" width="100%"/>
      </div>
      <div class="beg-price-left" :class='$mq'>
        <KImage :src="require('@/assets/begcourse/right-top.png')" alt="Art doll" width="100%"/>
      </div>
      <h2 :class='$mq'>Выбирайте <br/><span>формат</span></h2>
      <div class="beg-pricing-cont" :class='$mq'>
        <div class="beg-pricing-block" :class='$mq'>
          <h3>Сама</h3>
          <ul class="ul-pricing" :class='$mq'>
            <li><b>Блок 1.</b> Первый шаг, самый важный. Основа.</li>
            <li><b>Блок 2.</b> Лепим лицо, все не так сложно как кажется</li>
            <li><b>Блок 3.</b> Шарниры — движение куклы!</li>
            <li><b>Блок 4.</b> Оживляем куклу росписью</li>
            <li><b>Блок 5.</b> Создаем натуральную прическу без навыков парикмахера</li>
            <li class='no'><b>Блок 6.</b> Шьем платье</li>
            <li><b>БОНУС.</b> Запись "Эмоционального марафона"</li>
            <li class='no'>Закрытый чат</li>
            <li class='no'>Проверка ДЗ и индивидуальное сопровождение куратором 2 месяца</li>
            <li>Доступ на 6 месяцев</li>
            <li class='no'>3 индивидульные видеоконсультации</li>
          </ul>
          <p class="price" :class='$mq'>8 500₽</p>
          <p class="old-price" :class='$mq'></p>
<!--          <p class="leftplaces" :class='$mq'>Осталось 20 мест</p>-->
          <KRoundedButton @click="tryToBuy($event, 13)" class='beg-price-bt'
                          text-color="#fff" color="#cc6157"
                          v-scroll-to="'#second'">
            Создать куклу
            <template v-slot:icon>
              <KIHandFlower icon-color="#FFF" height="50px" width="50px"/>
            </template>
          </KRoundedButton>

          <a class="price-part" :class="$mq" @click="showPopup1()">Купить в рассрочку</a>
        </div>

        <div class="beg-pricing-block leader" :class='$mq'>
          <h3>С куратором</h3>
          <div class="goldbow" :class='$mq'>
            <div class="goldbow-rib"></div>
            <div class="goldbow-bow">
              <KImage :src="require('@/assets/begcourse/bow.png')" alt="Art doll" width="100%"/>
            </div>
          </div>
          <ul class="pricing" :class='$mq'>
            <li><b>Блок 1.</b> Первый шаг, самый важный. Основа.</li>
            <li><b>Блок 2.</b> Лепим лицо, все не так сложно как кажется</li>
            <li><b>Блок 3.</b> Шарниры — движение куклы!</li>
            <li><b>Блок 4.</b> Оживляем куклу росписью</li>
            <li><b>Блок 5.</b> Создаем натуральную прическу без навыков парикмахера</li>
            <li><b>Блок 6.</b> Шьем платье</li>
            <li><b>БОНУС.</b> Запись "Эмоционального марафона"</li>
            <li>Закрытый чат</li>
            <li>Проверка ДЗ и индивидуальное сопровождение куратором 2 месяца</li>
            <li>Доступ на 1 год</li>
            <li class='no'>3 индивидульные видеоконсультации</li>
          </ul>
          <p class="price" :class='$mq'>11 000₽</p>
          <p class="old-price" :class='$mq'></p>
<!--          <p class="leftplaces" :class='$mq'>Осталось 0 мест</p>-->

          <KRoundedButton @click="tryToBuy($event,14)"
                          class='disabled beg-price-bt'
                          text-color="#fff"
                          color="#706b6f">
            Создать куклу
            <template v-slot:icon>
              <KIHandFlower icon-color="#FFF" height="50px" width="50px"/>
            </template>
          </KRoundedButton>

          <a class="price-part disabled" :class="$mq" @click="showPopup2($event)">Купить в рассрочку</a>
        </div>

        <div class="beg-pricing-block" :class='$mq'>
          <h3>Со мной лично</h3>
          <ul class="pricing" :class='$mq'>
            <li><b>Блок 1.</b> Первый шаг, самый важный. Основа.</li>
            <li><b>Блок 2.</b> Лепим лицо, все не так сложно как кажется</li>
            <li><b>Блок 3.</b> Шарниры — движение куклы!</li>
            <li><b>Блок 4.</b> Оживляем куклу росписью</li>
            <li><b>Блок 5.</b> Создаем натуральную прическу без навыков парикмахера</li>
            <li><b>Блок 6.</b> Шьем платье</li>
            <li><b>БОНУС.</b> Запись "Эмоционального марафона"</li>
            <li>Закрытый чат</li>
            <li>Проверка ДЗ и индивидуальное сопровождение Людмилы 2 месяца</li>
            <li>Доступ на 2 года</li>
            <li>3 индивидульные видеоконсультации</li>
          </ul>
          <p class="price" :class='$mq'>35 000₽</p>
          <p class="old-price" :class='$mq'></p>
<!--          <p class="leftplaces" :class='$mq'>Осталось 0 место</p>-->
          <KRoundedButton @click="tryToBuy($event, 15)"
                          class='disabled beg-price-bt'
                          text-color="#fff"
                          color="#706b6f">
            Создать куклу
            <template v-slot:icon>
              <KIHandFlower icon-color="#FFF" height="50px" width="50px"/>
            </template>
          </KRoundedButton>

          <a class="disabled price-part" :class="$mq" @click="showPopup3($event)">Купить в рассрочку</a>
        </div>
      </div>
    </div>

    <!-- REVIEWS -->
    <div class="reviews" :class='$mq'>
      <h2 :class='$mq'>Работы<br/><span>участниц</span></h2>
      <div class="review-dark left"></div>
      <splide :slides="items" :options="options">
        <splide-slide v-for="(slide, index) in items" :key="index">
          <KImage :src="slide" @click="showImage(slide)" width="100%"></KImage>
        </splide-slide>
      </splide>
      <div class="review-dark right"></div>
    </div>

    <!-- FAQ -->
    <div class="faq" :class='$mq'>
      <h2 class="center faq" :class='$mq'>Часто задаваемые вопросы</h2>
      <accordion>
        <accordion-panel>
          <accordion-panel-header>Где мне будут доступны материалы? На какой платформе?
          </accordion-panel-header>
          <accordion-panel-content>Все материалы будут доступны на этом сайте <a href="https://kibanovadolls.ru/mycourses">в вашем личном кабинете.</a>
          </accordion-panel-content>
        </accordion-panel>
        <accordion-panel>
          <accordion-panel-header>Книга только электронная или есть бумажная версия?</accordion-panel-header>
          <accordion-panel-content>Бумажной версии, к сожалению нет. Книга в только электронном видео.
          </accordion-panel-content>
        </accordion-panel>
        <accordion-panel>
          <accordion-panel-header>Это подходит для начинающих?</accordion-panel-header>
          <accordion-panel-content>Прежде всего эта книга была <b>написана для новичков</b>, для тех, кто
            только пробует свои силы в кукольном творчестве. Так же этот материал будет интересен и тем, кто
            давно <b>хотел попробовать себя в теме шарнирной куклы</b>. Это базовые знания помогут вам
            получить удовольствие от процесса и избежать многих ошибок. Применив эти знания в своем
            творчестве Вы в разы увеличите выразительность образа своей куклы, а также решите, хотите ли вы
            дальше изучать строение шарниров.
          </accordion-panel-content>
        </accordion-panel>
        <accordion-panel>
          <accordion-panel-header>Как долго я смогу изучать материал? Есть ли ограничения по времени?
          </accordion-panel-header>
          <accordion-panel-content>Материал доступен 1 год. После вы сможете продлить доступ по вашему
            желанию.
          </accordion-panel-content>
        </accordion-panel>
        <accordion-panel>
          <accordion-panel-header>Смогу ли я изучать курс в удобное для меня время?</accordion-panel-header>
          <accordion-panel-content>Конечно, когда вам удобно, на любом устройстве.</accordion-panel-content>
        </accordion-panel>
        <accordion-panel>
          <accordion-panel-header>Я получу доступ сразу после оплаты?</accordion-panel-header>
          <accordion-panel-content>Да, вам на почту придет письмо с дальнейшими инструкциями.
          </accordion-panel-content>
        </accordion-panel>
        <accordion-panel>
          <accordion-panel-header>Какие материалы нужны для создания куклы?</accordion-panel-header>
          <accordion-panel-content><a target="_blank" href="/mycourses/bodoir-materials">Здесь можно посмотреть подробный список</a> всех материалов (чтобы его увидеть
            нужно авторизоваться)
          </accordion-panel-content>
        </accordion-panel>
      </accordion>
    </div>
  </div>
</template>

<script>
import KImage from "../../Widgets/KImage";
import KRoundedButton from "../../Widgets/KRoundedButton";
import KIHandFlower from "../../Widgets/Icons/KIHandFlower";
import {Splide, SplideSlide} from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import image1 from "@/assets/newyear/reviews/Beg1.jpg";
import image2 from "@/assets/newyear/reviews/Beg2.jpg";
import image3 from "@/assets/newyear/reviews/Beg3.jpg";
import image4 from "@/assets/newyear/reviews/Beg4.jpg";
import image5 from "@/assets/newyear/reviews/Beg5.jpg";
import image6 from "@/assets/newyear/reviews/Beg6.jpg";

export default {
  name: "BegCourse",
  components: {
    KImage,
    KRoundedButton,
    KIHandFlower,
    Splide,
    SplideSlide,
  },

  data() {
    return {
      items: [
        image1,
        image2,
        image3,
        image4,
        image5,
        image6,
      ],
      options: {
        rewind: true,
        perPage: 4,
        gap: '0.5rem',
        pagination: false,
        // cover: true,
        breakpoints: {
          450: {
            perPage: 1,
          },
          700: {
            perPage: 3,
          },
          1000: {
            perPage: 4,
          },
        },
      },
    }
  },
  methods: {
    tryToBuy(event, id) {
      if (!event.currentTarget.classList.contains("disabled")) {
        this.$router.push("/item/" + id)
      }
    },
    showImage(src) {
      this.$swal.fire({
        // width: '80%',
        height: '90%',
        background: '#eae2e0',
        html: '<img width="100%" src="' + src + '"/>',
        showCloseButton: true,
        showConfirmButton: false,
        // closeButtonHtml: "",
      })
    },
    playVideo() {
      let videoId = 'yjKT_tmMuFw'
      let videoWidth = window.innerWidth < 800 ? window.innerWidth : 800
      let videoHeight = videoWidth / 1.77
      this.$swal.fire({
        width: videoWidth + 'px',
        background: '#eae2e0',
        html: "<iframe frameborder=\"0\" allowfullscreen=\"1\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" title=\"YouTube video player\" width=\"" + videoWidth + "\" height=\"" + videoHeight + "\" src=\"https://www.youtube.com/embed/" + videoId + "?autoplay=1&controls=0&rel=0&modestbranding=0&loop=1&enablejsapi=1&origin=https%3A%2F%2Fkibanovadolls.ru&widgetid=1\"></iframe>",
        showCloseButton: true,
        showConfirmButton: false,
        // closeButtonHtml: ""
      })
    },
    showPopup1() {
      this.$swal.fire({
        customClass: 'popup',
        background: '#ffffff',
        html: '<h2>Условия рассрочки</h2> <p>Вы можете воспользоваться беспроцентной рассрочкой и оплатить курс 3 равными частями.</p> <ul><li>— 1-ый взнос 2000₽</li><li>— 2-ой взнос 2 250₽ через 2 недели</li><li>— 3-ий взнос 2 250₽ через месяц</li></ul><button onclick="location.href=\'https://kibanovadolls.ru/item/16\';" class="buy">Оплатить 2000₽</button>',
        showCloseButton: true,
        showConfirmButton: false,
        // closeButtonHtml: "",
      })
    },
    showPopup2(event) {
      if (!event.currentTarget.classList.contains("disabled")) {
        this.$swal.fire({
          customClass: 'popup',
          background: '#ffffff',
          html: '<h2>Условия рассрочки</h2> <p>Вы можете воспользоваться беспроцентной рассрочкой и оплатить курс 3 равными частями.</p> <ul><li>— 1-ый взнос 3000₽</li><li>— 2-ой взнос 3000₽ через 2 недели</li><li>— 3-ий взнос 3000₽ через месяц</li></ul><button onclick="location.href=\'https://kibanovadolls.ru/item/17\';" class="buy">Оплатить 3000₽</button>',
          showCloseButton: true,
          showConfirmButton: false,
          // closeButtonHtml: "",
        })
      }
    },
    showPopup3(event) {
      if (!event.currentTarget.classList.contains("disabled")) {
        this.$swal.fire({
          customClass: 'popup',
          background: '#ffffff',
          html: '<h2>Условия рассрочки</h2> <p>Вы можете воспользоваться беспроцентной рассрочкой и оплатить курс 3 равными частями.</p> <ul><li>— 1-ый взнос 10000₽</li><li>— 2-ой взнос 10000₽ через 2 недели</li><li>— 3-ий взнос 10000₽ через месяц</li></ul><button onclick="location.href=\'https://kibanovadolls.ru/item/18\';" class="buy">Оплатить 10 000₽</button>',
          showCloseButton: true,
          showConfirmButton: false,
          // closeButtonHtml: "",
        })
      }
    },
  }
}
</script>

<style>
  .popup {
    padding: 2em 2em 0 2em !important;
    font-family: "Montserrat", sans-serif;
    text-align: left!important;
  }
    .popup h2 {
      font-family: "Montserrat", sans-serif;
      text-align: left;
      font-size: 1.8em;
      padding-bottom: 0.5em;
    }
    .popup p {
      margin-bottom: 1em;
      text-align: left;
    }
    .popup ul {
      text-align: left;
    }
    .popup ul li {
      margin-bottom: 1em;
      padding-left: 1em;
    }
    .popup button.buy {
      background-color: #cc6157;
      border: none;
      padding: 0.8em 2em;
      border-radius: 3em;;
      color: white;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
      box-shadow: 0 0 1em #cc6157;
      position: relative;
      top: 0.5em;
    }
    .popup button.buy:hover{
      transform: scale(1.1);
    }
</style>

<style scoped>
.beg-pricing-block.disabled {
  color: #726d71;
}

.beg-price-bt.disabled {
  cursor: default;
}

.beg-price-bt.disabled:hover {
  transform: scale(1);
}

.container {
  max-width: 950px;
  font-size: 0.95em;
  padding: 0 1em;
}

.container.halftablet,
.container.mobile {
  font-size: 0.8em;
}

.container.halftablet {
  padding: 0 0.5em;
}

.container.mobile {
  padding: 0 1em;
}

h2 {
  font-size: 5.5em;
  text-align: center;
  line-height: 0.6em;
  position: relative;
  margin-bottom: 0.8em;
  z-index: 2;
}
  h2.tablet {
    font-size: 5em;
  }
  h2.halftablet {
    font-size: 4em;
  }
  h2.mobile {
    font-size: 3em;
  }

h2 span {
  font-family: 'TheArtist', sans-serif;
  font-size: 0.8em;
  position: absolute;
  right: 20%;
}

h2.tablet span,
h2.mobile span {
  right: 10%;
}

h2.halftablet span {
  right: 25%;
}

/* MAIN SCREEN */
.beg-screen {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: relative;
  margin-bottom: -10em;
}

.beg-screen.tablet {
  margin-bottom: -4em;
}

.beg-screen.halftablet,
.beg-screen.mobile {
  margin-bottom: -2em;
}

.beg-screen.mobile {
  margin-bottom: -6em;
  align-items: center;
}

.beg-h1 {
  width: 35%;
  padding-left: 10%;
  position: relative;
  z-index: 2;
}

.beg-h1.tablet,
.beg-h1.halftablet
.beg-h1.mobile {
  padding-left: 1em;
  width: 50%;
}

.beg-h1.mobile {
  padding-left: 0;
  width: 60%;
  top: -3em;
}

.beg-h1.tablet h1 {
  font-size: 3em;
}

.beg-h1.halftablet h1 {
  padding-bottom: 1.5em;
}

.beg-h1.mobile h1 {
  font-size: 2em;
  padding-bottom: 1.5em;
}

.beg-h1.halftablet h1 .script,
.beg-h1.mobile h1 .script {
  bottom: 0.5em;
}

/* BUTTON */
.beg-main-bt.mob {
  display: none;
}

.beg-main-bt.mob.mobile {
  display: flex;
  width: 100%;
  margin: 0 auto 2em auto;
  font-size: 1em;
  box-sizing: border-box;
  padding: 0.5em 1em;
}

.beg-h1.tablet .beg-main-bt {
  padding: 0.5em 1em;
  font-size: 1em;
}

.beg-h1.halftablet .beg-main-bt {
  padding: 0.3em 1em;
  font-size: 1em;
}

.beg-h1.mobile .beg-main-bt {
  display: none;
}

h1 {
  font-size: 3.5em;
  line-height: 1em;
  position: relative;
  padding-bottom: 2em;
  top: 0.5em;
}

h1 span.big {
  font-size: 2.5em;
  line-height: 0.7em;
}

h1 span.script {
  font-family: 'TheArtist', sans-serif;
  position: absolute;
  right: 0em;
  bottom: 1em;
}

.beg-img-block {
  width: 100%;
  z-index: 0;
}

.beg-img {
  width: 710px;
  position: relative;
  top: -8em;
  right: 2.3em;
}

.beg-img.tablet {
  width: 550px;
  top: -5em;
  right: 0em;
}

.beg-img.halftablet {
  width: 350px;
  top: -3em;
  right: 0em;
}

.beg-img.mobile {
  width: 320px;
  top: -3em;
  right: 5em;
}

.beg-video {
  position: absolute;
  bottom: 20em;
  right: 0em;
  max-width: 120px;
  font-size: 0.8em;
  text-align: center;
  line-height: 1.3em;
  cursor: pointer;
}

.beg-video.tablet {
  right: 0em;
  bottom: 10em;
}

.beg-video.halftablet,
.beg-video.mobile {
  display: none;
}

.beg-video-img {
  width: 68px;
  margin: 0.6em auto;
  animation: video 1s ease-in-out infinite;
  position: relative;
  z-index: 1;
}

.beg-video-circle {
  width: 68px;
  height: 68px;
  position: absolute;
  top: 0;
  left: 0;
  background: #d0918355;
  border-radius: 100px;
  z-index: -1;
}

@keyframes video {
  0% {
    transform: scale(1.0);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1.0);
  }
}

.beg-top-right, .beg-top-left, .beg-bottom-left {
  position: absolute;
  z-index: 2;
}

.beg-top-left {
  width: 258px;
  top: -7em;
  left: -11em;
}

.beg-top-left.tablet,
.beg-top-left.halftablet,
.beg-top-left.mobile {
  left: -3em;
}

.beg-top-left.halftablet,
.beg-top-left.mobile {
  width: 200px;
}

.beg-top-right {
  width: 225px;
  top: -7em;
  right: -11em;
}

.beg-top-right.halftablet,
.beg-top-right.mobile {
  display: none;
}

.beg-bottom-left {
  width: 318px;
  bottom: 7em;
  left: -7em;
}

.beg-bottom-left.halftablet,
.beg-bottom-left.mobile {
  display: none;
}

.light-pink, .light-green {
  position: absolute;
  width: 700px;
  height: 700px;
  z-index: 1;
}

.light-pink {
  top: -500px;
  left: -350px;
  background: radial-gradient(circle 350px, #834339, transparent 100%);
}

.light-green {
  bottom: 0;
  right: -100%;
  background: red;
  z-index: 1;
  background: radial-gradient(circle 350px, #3e6c63, transparent 100%);
}

.light-green.tablet,
.light-green.halftablet,
.light-green.mobile {
  display: none;
}

/* ADVANTAGES */
.beg-adv-cont {
  position: relative;
  z-index: 2;
}

.beg-adv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  position: relative;
  padding: 2em 0;
  color: #d09183;
  line-height: 1.2em;
}

.beg-adv.tablet,
.beg-adv.halftablet,
.beg-adv.mobile {
  padding: 1em 0;
}

.beg-adv.halftablet {
  font-size: 0.9em;
}

.beg-adv.mobile {
  font-size: 0.8em;
}

.beg-adv-block {
  width: 25%;
  text-align: center;
}

.beg-adv-block {
  width: 28%;
}

.beg-adv-block span {
  font-size: 4em;
  font-style: italic;
  font-weight: 400;
  line-height: 1em;
}

.beg-adv-block.tablet span,
.beg-adv-block.halftablet span {
  font-size: 3em;
  line-height: 1.5em;
}

.beg-adv-block.halftablet span,
.beg-adv-block.mobile span {
  font-size: 2.5em;
  line-height: 1.5em;
}

.adv-line {
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, rgba(208, 145, 131, 0) 0%, rgba(208, 145, 131, 1) 7%, rgba(208, 145, 131, 1) 91%, rgba(208, 145, 131, 0) 100%);
}

/* VIDEO */
.video-mob-cont {
  display: none;
}

.video-mob-cont.mobile {
  display: block;
  margin-top: 2em;
}

.video-mob-block {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  box-sizing: border-box;
  margin-bottom: 1em;
}

.video-mob {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* PRICING */
.beg-pricing {
  margin-top: 4em;
  margin-bottom: 5em;
  position: relative;
}

.beg-pricing.halftablet {
  font-size: 0.8em;
}

.beg-pricing.mobile {
  font-size: 0.9em;
}

.beg-pricing-cont {
  display: flex;
  justify-content: space-between;
}

.beg-pricing-cont.mobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.beg-pricing-block {
  width: 33%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2.5em 2em 1em 2em;
}

.beg-pricing-block.tablet,
.beg-pricing-block.halftablet {
  padding: 2.5em 1em 1em 1em;
}

.beg-pricing-block.mobile {
  width: 100%;
  padding: 2.5em 2em 1em 2em;
  background: #d0918333;
  border-radius: 1.5em;
  margin-bottom: 2em;
}

.beg-pricing-block.leader {
  background: #d0918333;
  border-radius: 1.5em;
}

.beg-pricing-block.mobile.leader {
  background: none;
}

.beg-pricing-block.leader .beg-price-bt {
  width: 100%;
}

/* GOLDBOW */
.goldbow {
  width: 100%;
  position: relative;
  margin: 1em 0;
  display: none;
}

.goldbow.mobile {
  display: block;
}

.goldbow-rib {
  height: 0.3em;
  background: rgb(200, 134, 40);
  background: linear-gradient(90deg,
  rgba(200, 134, 40, 1) 0%,
  rgba(237, 201, 112, 1) 10%,
  rgba(194, 113, 25, 1) 20%,
  rgba(194, 113, 25, 1) 80%,
  rgba(237, 201, 112, 1) 90%,
  rgba(200, 134, 40, 1) 100%);
}

.goldbow-bow {
  width: 5em;
  position: absolute;
  top: -1em;
  left: 50%;
  transform: translateX(-50%);
}


.beg-pricing h3 {
  font-size: 1.9em;
  text-transform: uppercase;
  line-height: 1em;
  margin-bottom: 0.5em;
}

.beg-pricing.tablet h3,
.beg-pricing.halftablet h3 {
  font-size: 1.5em;
}

.beg-pricing.mobile h3 {
  text-align: left;
}

.beg-pricing b {
  font-weight: 600;
}

.beg-pricing ul {
  line-height: 1.2em;
  text-align: left;
}

.beg-pricing ul li {
  padding: 0.7em 0 0.7em 2em;
  background: url("~@/assets/book/price-check.svg") no-repeat;
  background-position-y: 10px;;
  background-size: 0.8em;
}

.beg-pricing.tablet ul li,
.beg-pricing.halftablet ul li,
.beg-pricing.mobile ul li {
  padding: 0.7em 0 0.7em 1.5em;
}

.beg-pricing ul li.no {
  background-position-y: -10em;
  color: #726d71;
}

.price, .old-price {
  font-family: 'Kudryashev', sans-serif;
  line-height: 0.8em;
}

.price {
  font-size: 3em;
  margin-top: 0.7em;
}

.old-price {
  font-size: 2em;
  color: #726d71;
  text-decoration: line-through;
  margin-bottom: 0.5em;
}

.leftplaces {
  font-style: italic;
  font-size: 0.85em;
  margin-bottom: 1em;;
}

.beg-price-bt {
  width: 90%;
  font-size: 1em;
  padding: 0.5em 1em;
  margin-bottom: 1em;
}

.beg-pricing-block.mobile .beg-price-bt {
  font-size: 1.2em;
}

.price-part {
  color: white;
  font-style: italic;
  font-size: 0.8em;
  text-decoration: underline;
  border: 1px solid white;
  border-radius: 3em;
  padding: 1em 2em;
  display: block;
  width: 90%;
  box-sizing: border-box;
}

.price-part.disabled {
  color: #706b6f;
  border-color: #706b6f;
  cursor: default;
}

.price-part.disabled:hover {
  text-decoration: underline;
}

.price-part:hover {
  text-decoration: none;
}

.beg-price-right, .beg-price-left {
  position: absolute;
  z-index: 1;
}

.beg-price-right.halftablet, .beg-price-left.halftablet,
.beg-price-right.mobile, .beg-price-left.mobile {
  display: none;
}

.beg-price-right {
  top: -5%;
  right: -20%;
  width: 318px;
  transform: rotate(-10deg);
}

.beg-price-left {
  bottom: 0;
  left: -20%;
  width: 225px;
}

/* REVIEWS */
.reviews {
  margin: 0 auto;
  position: relative;
}

.review-dark {
  width: 150px;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  pointer-events: none;
}

.reviews.halftablet .review-dark {
  width: 100px;
}

.reviews.mobile .review-dark {
  width: 50px;
}

.review-dark.left {
  background: linear-gradient(270deg, rgba(29, 12, 41, 0) 0%, rgba(29, 12, 41, 1) 100%);
  left: 0;
}

.review-dark.right {
  background: linear-gradient(90deg, rgba(29, 12, 41, 0) 0%, rgba(29, 12, 41, 1) 100%);
  right: 0;
}

.reviews.tablet,
.reviews.halftablet,
.reviews.mobile {
  width: 90%;
}

.reviews.halftablet,
.reviews.mobile {
  width: 90%;
  margin-top: -20pt;
}

.reviews p {
  margin-top: 10pt;
}

.reviews.tablet p,
.reviews.halftablet p,
.reviews.mobile p {
  font-size: 0.8em;
  line-height: 1.2;
}

.reviews.mobile p {
  font-size: 0.7em;
  line-height: 1.2;
}

.reviews.halftablet p,
.reviews.mobile p {
  width: 80%;
  margin: 10pt auto 0 auto;
}

</style>

<style scoped>
.faq {
  margin-top: 1em;
  font-size: 0.9em;
}

h2.faq {
  font-size: 3em;
  margin-bottom: 0.5em;
}
h2.faq.mobile {
  font-size: 2em;
}

h2.center {
  text-align: center;
  margin-bottom: 1em;
}

:deep(.accordion__panel button) {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

:deep(.accordion__panel .accordion__panel-header-toggle) {
  width: 100%;
  display: block;
  text-align: left;
  border: 0px solid transparent;
  border-radius: 0.5em;
  background: #00000022;
  color: white;
  padding: 0.8em 1.5em;
  margin-bottom: 0.5em;
}

:deep(.accordion__panel-content) {
  font-size: 0.9em;
  margin: 0 2em 1.5em 2em;
  animation-name: open;
  animation-duration: 0.6s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-delay: 0s;
}

@keyframes open {
  0% {
    display: block;
    opacity: 0;
    visibility: hidden;
  }
  100% {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}
</style>