import { render } from "./BegCourse.vue?vue&type=template&id=7ae6095f&scoped=true"
import script from "./BegCourse.vue?vue&type=script&lang=js"
export * from "./BegCourse.vue?vue&type=script&lang=js"

import "./BegCourse.vue?vue&type=style&index=0&id=7ae6095f&lang=css"
import "./BegCourse.vue?vue&type=style&index=1&id=7ae6095f&scoped=true&lang=css"
import "./BegCourse.vue?vue&type=style&index=2&id=7ae6095f&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-7ae6095f"

export default script